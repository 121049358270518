import {html, LitElement, PropertyValues, TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import Styles from "./feedListPreview.lit.scss";
import type {FeedChannel} from "../feedCommons";
import type {ContentEntryResponse} from "../feedService";
import {EopOverlayImageSpinner} from "../../../page/elements/spinner";
import {EOP_ERRORS, schedule} from "../../../common/utils/promises";
import {LocatedDates} from "../../../common/utils/dates/locatedDates";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {resolve} from "../../../container";
import type {PropertyMap} from "../../../common/utils/objects";
import {DefaultFeedSource} from "../feedSource";
import {FeedEntry} from "../feedEntry";


@customElement("eop-feed-list-preview")
export class EopFeedListPreview extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "limit", type: Number})
    private limit: number;
    @property({attribute: "language"})
    private language: string;
    @property({attribute: "sources", type: Object})
    private sources: PropertyMap<FeedChannel>;
    @property({attribute: "keywords"})
    private keywords: string;
    @property({attribute: "excluded-keywords"})
    private excludedKeywords: string;

    @state()
    private entries: FeedEntry[];

    private spinner: EopOverlayImageSpinner;

    public constructor(
        private feedSource: DefaultFeedSource = new DefaultFeedSource(),
        private dates: LocatedDates = resolve(LocatedDates)
    ) {
        super();
        this.entries = [];
        this.spinner = new EopOverlayImageSpinner();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.feedSource.configure({
            sources: this.sources,
            language: this.language ?? "",
            keywords: this.keywords?.parseCommaSeparated() ?? [],
            excludedKeywords: this.excludedKeywords?.parseCommaSeparated() ?? [],
            characterLimit: 0
        });
    }

    public render(): TemplateResult {
        return html`
            ${this.spinner}
            ${this.renderEntriesElement()}
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        schedule(this.spinWhileFetchItems()).as("fetch");
    }

    private renderEntriesElement(): TemplateResult | null {
        if (this.entries.isEmpty()) {
            return null;
        }

        return html`
            <div class="items">${(this.renderEntries())}</div>`;
    }

    private renderEntries(): TemplateResult[] {
        return this.entries.map(entry => this.renderEntry((entry)));
    }

    private renderEntry(entry: FeedEntry): TemplateResult {
        const date = this.dates.toLocalDateString(new Date(entry.data.publishedDate));
        const topics = (entry.data.topics ?? []).map(t => t.name).join(", ");

        return html`
            <div class="item">
                <div class="header-row">${date} | ${topics}</div>
                <a href=${entry.data.url}>${unsafeHTML(entry.data.headline ?? "")}</a>
            </div>
        `;
    }

    private async spinWhileFetchItems(): Promise<void> {
        await this.spinner.spinWhile(this.fetchItems(this.limit, 0)
            .then(response => {
                this.entries = response.map(entryResponse => FeedEntry.fromResponse(entryResponse));
            })).catch(EOP_ERRORS);
    }

    private async fetchItems(size: number, offset: number): Promise<ContentEntryResponse[]> {
        return this.feedSource.fetch(size, offset);
    }
}