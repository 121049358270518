import {customElement} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import {resolve} from "../../../../container";
import Styles from "./siteSearch.lit.scss";
import {SiteSearchBackend} from "./siteSearchBackend";
import {FilteredSearch} from "../filteredSearch";

@customElement("eop-site-search")
export class EopSiteSearch extends FilteredSearch {

    public static readonly styles = Styles;

    public constructor(
        backend: SiteSearchBackend = resolve(SiteSearchBackend)
    ) {
        super();
        this.searchFacade.registerBackend(backend);
    }

    public render(): TemplateResult {
        return html`
            <eop-overlay-spinner event="search,more" class="content-search-spinner"></eop-overlay-spinner>
            ${this.renderResultsLabel()}
            <eop-site-search-tiles class="tiles"></eop-site-search-tiles>
            ${this.renderSearchTags()}
            <div class="footer">
                ${this.renderMoreButton()}
            </div>`;
    }

    private renderSearchTags(): TemplateResult | null {
        if (this.searchFacade.totalCount() !== 0) {
            return null;
        }

        return html`
            <eop-search-tags .tags=${this.tags} .targetUrl=${this.targetUrl} class="initial" data-eventelement="tags"></eop-search-tags>`;
    }
}
