import type {FeedSource} from "../feedSource";
import type {EopFeedTile, FeedTileConfig} from "./feedTile";
import {FeedEntry} from "../feedEntry";
import type {EopOverlaySpinner, Spinner} from "../../../page/elements/spinner";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {MultiRowFeedPreviewSize, SingleRowFeedPreviewSize} from "../feedPreviewSize";
import {schedule} from "../../../common/utils/promises";
import {html, LitElement, type PropertyValues, TemplateResult} from "lit";
import Styles from "./feedPreviewSlider.lit.scss";
import GridStyles from "./feedPreviewGrid.lit.scss";
import {property, query, state} from "lit/decorators.js";
import {ANIMATION_DURATION} from "../../../common/gridLayout";
import {Timeout} from "../../../common/timeout";
import {ELEMENT_SCROLL_PADDING, ScrollService} from "../../../common/scroll";

export class FeedEntriesCollector {
    public constructor(private feedSource: FeedSource) {
    }

    public async collectFeedEntries(size: number, offset: number): Promise<FeedEntry[]> {
        const feeds = await this.feedSource.fetch(size, offset);
        return feeds.map(feed => {
            return FeedEntry.fromResponse(feed)
                .inChannelContext(this.feedSource.channels());
        });
    }
}

export abstract class AbstractFeedPreviewGrid<F extends FeedSource> extends LitElement {

    public static readonly styles = GridStyles;

    @property({attribute: "character-limit", type: Number})
    public characterLimit: number = 180;
    @property({attribute: "information-density"})
    public informationDensity: string = "";
    @property({attribute: "card-boxing"})
    public cardBoxing: string = "";
    @property({attribute: "linked-topics"})
    private linkedTopics: boolean = false;
    @property({attribute: "use-page-overlay"})
    public usePageOverlay: boolean = false;
    @property({attribute: "context-preserving-page-overlay"})
    public contextPreservingPageOverlay: boolean = false;
    @property({attribute: "size"})
    public size: string | undefined = undefined;
    @property({attribute: "arrangement"})
    private arrangement: string = "";
    @state()
    private feedEntries: FeedEntry[] = [];
    @state()
    private limit: number;

    @query("eop-image-spinner")
    protected fetchSpinner: Spinner;
    @query("eop-overlay-spinner")
    protected fetchMoreSpinner: EopOverlaySpinner;
    @query(".feed-tiles")
    protected feedTilesContainer: HTMLElement;

    private feedEntriesCollector: FeedEntriesCollector;
    private feedTilesConfig: FeedTileConfig;
    protected enableMore: boolean;

    protected constructor(
        protected feedSource: F,
        private resolution: Resolution = resolve(Resolution),
        private scrollService: ScrollService = resolve(ScrollService),
        private timeout: Timeout = resolve(Timeout)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.configure();
        this.limit = new MultiRowFeedPreviewSize(this.size, this.arrangement, this.resolution).getTileAmount();
        this.feedEntriesCollector = new FeedEntriesCollector(this.feedSource);
        this.feedTilesConfig = {
            arrangement: this.arrangement,
            informationDensityClass: this.informationDensity,
            cardBoxingClass: this.cardBoxing,
            usePageOverlay: this.usePageOverlay,
            contextPreservingPageOverlay: this.contextPreservingPageOverlay,
            topicsLinked: this.linkedTopics
        };

        if (this.arrangement) {
            this.classList.add(this.arrangement);
        }
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        schedule(this.fetchSpinner.spinWhile(this.fetchFeedEntries(this.limit, 0)
            .then(entries => this.feedEntries = entries)))
            .as("fetch");
    }

    protected abstract configure(): void;

    public render(): TemplateResult | undefined {
        return html`
            <eop-overlay-spinner></eop-overlay-spinner>
            <eop-image-spinner></eop-image-spinner>
            ${this.renderTiles()}
            ${this.renderFetchMoreButton()}
        `;
    }

    private renderTiles(): TemplateResult | null {
        if (this.feedEntries.isEmpty()) {
            return null;
        }
        return html`
            <div class="feed-tiles">${(this.renderFeedTiles())}</div>`;
    }

    private renderFeedTiles(): TemplateResult[] {
        return this.feedEntries.map(entry => html`
            <eop-feed-tile .entry=${entry} .config=${this.feedTilesConfig}></eop-feed-tile>`);
    }

    private renderFetchMoreButton(): TemplateResult | null {
        if (this.showFetchMore()) {
            return html`
                <div class="fetch-more-button">
                    <button type="button" class="primary" @click=${this.fetchMore}>
                        <eop-msg key="FEED_FETCH_MORE"></eop-msg>
                    </button>
                </div>
            `;
        }
        return null;
    }

    protected fetchMore(): void {
        const offset = this.feedEntries.length;
        schedule(this.fetchMoreSpinner.spinWhile(this.fetchFeedEntries(this.limit, offset)
            .then(entries => {
                this.feedEntries = this.feedEntries.slice(0, offset).concat(entries);
                this.scrollToNextElement(offset);
            })))
            .as("more");
    }

    private showFetchMore(): boolean {
        return this.enableMore
            && this.feedSource.total() > this.feedEntries.length;
    }

    private scrollToNextElement(offset: number): void {
        this.timeout.delay(() => {
            const nextTile = this.renderRoot.querySelectorAll<EopFeedTile>("eop-feed-tile").item(offset);
            void this.scrollService.scrollToElement(this.feedTilesContainer, -nextTile.offsetTop + ELEMENT_SCROLL_PADDING, ANIMATION_DURATION);
        });
    }

    private async fetchFeedEntries(size: number, offset: number): Promise<FeedEntry[]> {
        return await this.feedEntriesCollector.collectFeedEntries(size, offset);
    }
}

export abstract class AbstractFeedPreviewSlider<F extends FeedSource> extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "character-limit", type: Number})
    public characterLimit: number = 180;
    @property({attribute: "information-density"})
    public informationDensity: string = "";
    @property({attribute: "card-boxing"})
    public cardBoxing: string = "";
    @property({attribute: "linked-topics"})
    private linkedTopics: boolean = false;
    @property({attribute: "use-page-overlay"})
    public usePageOverlay: boolean = false;
    @property({attribute: "context-preserving-page-overlay"})
    public contextPreservingPageOverlay: boolean = false;
    @property({attribute: "size"})
    public size: string | undefined = undefined;

    @state()
    private feedEntries: FeedEntry[] = [];
    @state()
    private limit: number;

    @query("eop-image-spinner")
    protected spinner: Spinner;

    private feedTileCollector: FeedEntriesCollector;
    private feedTilesConfig: FeedTileConfig;

    protected constructor(
        protected feedSource: F,
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.configure();
        this.limit = new SingleRowFeedPreviewSize(this.size, this.resolution).getTileAmount();

        this.feedTileCollector = new FeedEntriesCollector(this.feedSource);
        this.feedTilesConfig = {
            arrangement: "",
            informationDensityClass: this.informationDensity,
            cardBoxingClass: this.cardBoxing,
            usePageOverlay: this.usePageOverlay,
            contextPreservingPageOverlay: this.contextPreservingPageOverlay,
            topicsLinked: this.linkedTopics
        };
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        schedule(this.spinner.spinWhile(this.fetchFeedEntries(this.limit, 0)
            .then(entries => this.feedEntries = entries)))
            .as("fetch");
    }

    protected abstract configure(): void;

    public render(): TemplateResult | undefined {
        return html`
            <eop-image-spinner></eop-image-spinner>
            <div class="multi-carousel">
                ${this.renderMultiCarousel()}
            </div>
        `;
    }

    private renderMultiCarousel(): TemplateResult | null {
        if (this.feedEntries.isEmpty()) {
            return null;
        }
        return html`
            <eop-multi-carousel max-elements-per-slide="4">
                ${this.renderTiles()}
            </eop-multi-carousel>
        `;
    }

    private renderTiles(): TemplateResult | null {
        if (this.feedEntries.isEmpty()) {
            return null;
        }
        const feedEntries = this.feedEntries.map(entry => html`
            <eop-feed-tile slot="slider-content" .entry=${entry} .config=${this.feedTilesConfig}></eop-feed-tile>`);
        return html`${feedEntries}`;
    }

    private async fetchFeedEntries(size: number, offset: number): Promise<FeedEntry[]> {
        return await this.feedTileCollector.collectFeedEntries(size, offset);
    }
}