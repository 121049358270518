import {html, LitElement, TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./feedTopicTag.lit.scss";
import type {FeedEntryTopic} from "../feedEntry";
import {type PageOverlayHandler, PageOverlayHandlerProvider} from "../../../page/elements/pageOverlay/pageOverlayHandler";
import {resolve} from "../../../container";

@customElement("eop-feed-topic-tag")
export class EopFeedTopicTag extends LitElement {

    public static readonly styles = Styles;

    @property({type: Boolean})
    private linked: boolean = false;
    @property({type: Object})
    private topic: FeedEntryTopic;

    private handler: PageOverlayHandler;

    public connectedCallback(): void {
        super.connectedCallback();
        this.setupHandler();
    }

    private async setupHandler(): Promise<void> {
        const handlerProvider: PageOverlayHandlerProvider = resolve(PageOverlayHandlerProvider);
        this.handler = await handlerProvider.getPageOverlayHandler();
    }

    public render(): TemplateResult {
        return html`
            <div class="tag-container">
                <div class="tag">
                    ${this.renderTagContent()}
                </div>
            </div>`;
    }

    private renderTagContent(): TemplateResult {
        if (this.linked && !!this.topic.url) {
            return html`
                <a href=${this.topic.url} class="link" @click=${this.handleClick}>${this.topic.name}</a>`;
        } else {
            return html`
                <div @click=${this.preventClick} class="plain-tag" data-tracking-label=${this.topic.name}>${this.topic.name}</div> `;
        }
    }

    private preventClick(e: Event): void {
        e.stopPropagation();
        e.preventDefault();
    }

    private handleClick(e: Event): void {
        e.stopPropagation();
        if (this.handler.didOpenOutsidePageOverlay(this.topic.url)) {
            e.preventDefault();
        }
    }
}