export class FeedEntriesRequest {
    public constructor(
        public readonly lang: string,
        public readonly limit: number,
        public readonly offset: number,
        public readonly keywords: string[],
        public readonly sources: string[],
        public readonly maxDescriptionLength: number,
        public readonly excludedKeywords: string[] = []
    ) {
    }
}

export class FeedRelatedRequest {
    public constructor(
        public readonly lang: string,
        public readonly limit: number,
        public readonly offset: number,
        public readonly sources: string[],
        public readonly maxDescriptionLength: number,
        public readonly relatedContentUrl: string
    ) {
    }
}

export class FeedEntriesByUrlsRequest {
    public constructor(
        public readonly urls: string[],
        public readonly maxDescriptionLength: number
    ) {
    }
}

export class NewsRequest {
    public constructor(
        public readonly lang: string,
        public readonly limit: number,
        public readonly offset: number,
        public readonly searchPhrase: string,
        public readonly sources: string[],
        public readonly maxDescriptionLength: number,
        public readonly lastVisitDate: number,
        public readonly filters: Map<string, string[]>,
        public readonly archive: boolean
    ) {
    }
}

export class SiteSearchRequest {
    public constructor(
        public readonly lang: string,
        public readonly limit: number,
        public readonly offset: number,
        public readonly searchPhrase: string,
        public readonly sources: string[],
        public readonly maxDescriptionLength: number,
        public readonly filters: Map<string, string[]>,
        public readonly archive: boolean
    ) {
    }
}

export class NewsCountRequest {
    public constructor(
        public readonly lang: string,
        public readonly searchPhrase: string,
        public readonly sources: string[],
        public readonly filters: Map<string, string[]>,
        public readonly archive: boolean
    ) {
    }
}

export class SiteSearchCountRequest {
    public constructor(
        public readonly lang: string,
        public readonly searchPhrase: string,
        public readonly sources: string[],
        public readonly filters: Map<string, string[]>,
        public readonly archive: boolean
    ) {
    }
}

export class FiltersRequest {
    public constructor(
        public readonly lang: string,
        public readonly sources: string[],
        public readonly archive: boolean
    ) {
    }
}

export class ContactsRequest {
    public constructor(
        public readonly lang: string,
        public readonly searchPhrase: string
    ) {
    }
}