import {QueryParameters} from "../../../../common/queryParameters";
import {PageScrollbar} from "../../../../common/pageScrollbar";
import {Resolution} from "../../../../common/resolution";
import {BOOTSTRAP} from "../../../../common/resolutionConstants";
import {Dictionary} from "../../../../page/elements/dictionary";
import {Deferred, EOP_ERRORS, Promises, schedule} from "../../../../common/utils/promises";
import {resolve} from "../../../../container";
import {Filters, SearchFacade} from "../searchFacade";
import {FILTER_TOGGLE_EVENT, FilterToggleEvent} from "./filterValue";
import {customElement, query, state} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./searchFilter.lit.scss";
import {Filter} from "./filterBlock";
import {EventBus} from "../../../../common/eventBus";
import {SEARCH_TILES_IN_VIEWPORT_EVENT} from "../searchGroup";
import {ManagingResources} from "../../../../common/lifetime";

const FILTER_OPEN_CLASS = "filter-open";
const SEARCH_FILTER_QUERY_PARAMETER_PREFIX = "filter_";
const RESULT_COUNT_PLACEHOLDER = "[COUNT]";

class FilterBlock {
    public constructor(
        public category: string,
        public filters: Filter[],
        public label: string,
        public disabled: boolean
    ) {
    }
}

@customElement("eop-search-filter")
export class EopSearchFilter extends ManagingResources(LitElement) {

    @state()
    private resultsLabel: string;
    @state()
    private filterBlocks: FilterBlock[];
    @state()
    private isUpToDate: boolean = true;

    @query(".filter-container")
    private filterContainer: HTMLElement;

    public static readonly styles = Styles;

    private dictionary: Dictionary;
    private totalCount: number;
    private countUpdater: Deferred<number>;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private eventBus: EventBus = resolve(EventBus),
        private searchFacade: SearchFacade = resolve(SearchFacade),
        private promises: Promises = resolve(Promises),
        private queryParameters: QueryParameters = resolve(QueryParameters)
    ) {
        super();

        this.filterBlocks = [];
        this.totalCount = 0;
        this.countUpdater = new Deferred();
        searchFacade.registerFilterInitialization(async (sf) => {
            this.queryParameters.onStringsStartingWith(SEARCH_FILTER_QUERY_PARAMETER_PREFIX, filters => {
                const initialFilters = new Map();
                filters.forEach((value, category) => {
                    const values = initialFilters.get(category) ?? [];
                    values.push(value);
                    initialFilters.set(category, values);
                });
                sf.addFilters(filters);
                sf.commitFilters();
            });
            await sf.fetchFilters();
            return sf;
        });
    }

    public render(): TemplateResult {
        const resetLabel = this.dictionary.translate("CONTENT_SEARCH_RESET");
        const searchLabel = this.dictionary.translate("CONTENT_SEARCH_FILTER");
        return html`
            <div class="search-filter" data-eventelement="feedfilter">
                <div class="shadow-overlay"></div>
                <div class="filter-container">
                    <div class="filter-panel">
                        <span class="close-filter" data-tracking-label="close-filter-dialog" @click=${this.closeFilter}></span>
                        <span class="reset-button" ?is-disabled=${!this.isResettable()} @click=${this.reset}>${resetLabel}</span>
                        ${(this.renderFilterBlocks())}
                        <div class="filter-panel-footer">
                            <button type="button" ?is-disabled=${this.isUpToDate} class="search-button primary" @click=${this.triggerSearch}>
                                ${this.resultsLabel}
                            </button>
                            <eop-overlay-image-spinner event="result-count"></eop-overlay-image-spinner>
                        </div>
                    </div>
                    <button type="button" class="filter-button secondary" @click=${this.toggleFilterPanel}>${searchLabel}</button>
                </div>
            </div>
        `;
    }

    private renderFilterBlocks(): TemplateResult[] {
        return this.filterBlocks.map(block => {
            return html`
                <eop-search-filter-block
                        .category=${block.category}
                        .label=${this.dictionary?.translate(block.label)}
                        .filters=${block.filters}
                        ?disabled=${block.disabled}
                ></eop-search-filter-block>
            `;
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.searchFacade.onUpdateFilter((_, totalCount) => {
            this.updateCount(totalCount);
            this.updateFilterBlocks();
        });
        this.searchFacade.onAppliedFilter(filters => this.persistFilters(filters));

        this.searchFacade.onChangeGroup(() => {
            this.updateFilterBlocks();

            this.updateTotalCount().then(() => {
                this.resultsLabel = this.getShowResultsLabel();
            });
        });

        this.updateFilterBlocks();
        this.dictionary = Dictionary.of(this);
        this.resolution.onBootstrapBreakpointChange(() => this.reactToBreakpointChange(), this);

        this.addEventListener(FILTER_TOGGLE_EVENT, (event: Event) => {
            const eventDetails = (event as FilterToggleEvent).detail;
            this.toggleFilter(eventDetails.category, eventDetails.value);
        });
        this.eventBus.on(SEARCH_TILES_IN_VIEWPORT_EVENT, event => {
            this.classList.toggle("tiles-in-viewport", event.data.inViewport);
        }, this);
    }

    private updateFilterBlocks(): void {
        this.filterBlocks = this.getFilterBlocks();
    }

    private async toggleFilterPanel(): Promise<void> {
        this.classList.toggle(FILTER_OPEN_CLASS);
        this.updateScrollBehaviour();

        if (this.classList.contains(FILTER_OPEN_CLASS)) {
            this.resetToAppliedState();
            this.isUpToDate = !this.isChanged();
            this.filterBlocks = this.getFilterBlocks();
            await this.updateTotalCount();
            this.resultsLabel = this.getShowResultsLabel();
        }
    }

    public async toggleFilter(category: string, value: string): Promise<void> {
        this.searchFacade.toggleFilter(category, value);
        await this.updateResultCount();
        this.updateProperties();
    }

    public async triggerSearch(): Promise<void> {
        await schedule(this.searchFacade.commitFilters()).as("trigger-search");
        this.closeFilter();
    }

    private closeFilter(): void {
        this.classList.remove(FILTER_OPEN_CLASS);
        this.updateScrollBehaviour();
    }

    public async reset(): Promise<void> {
        await schedule(this.internalReset()).as("filter-reset");
        this.updateProperties();
    }

    private async internalReset(): Promise<void> {
        await this.searchFacade.resetFilters();
        await this.updateResultCount();
    }

    private updateProperties(): void {
        this.filterBlocks = this.getFilterBlocks();
        this.resultsLabel = this.getShowResultsLabel();
        this.isUpToDate = !this.isChanged();
    }

    private getShowResultsLabel(): string {
        const totalCount = this.getTotalCount();
        const subkey = totalCount === 1 ? "1" : "_";
        return this.dictionary.translate("CONTENT_SEARCH_RESULTS_SHOW", subkey, {[RESULT_COUNT_PLACEHOLDER]: totalCount});
    }

    private updateScrollBehaviour(): void {
        if (this.resolution.downTo(BOOTSTRAP.MD)) {
            return;
        }
        if (this.classList.contains(FILTER_OPEN_CLASS)) {
            this.scrollFilterContainerToBottom();
            this.pageScrollbar.disablePageScrollability();
        } else {
            this.pageScrollbar.enablePageScrollability();
        }
    }

    private scrollFilterContainerToBottom(): void {
        this.filterContainer.scrollTop = this.filterContainer.scrollHeight;
    }

    private reactToBreakpointChange(): void {
        if (!this.classList.contains(FILTER_OPEN_CLASS)) {
            return;
        }
        if (this.resolution.upTo(BOOTSTRAP.SM)) {
            this.scrollFilterContainerToBottom();
            this.pageScrollbar.disablePageScrollability();
        } else {
            this.pageScrollbar.enablePageScrollability();
        }
    }

    public getFilterBlocks(): FilterBlock[] {
        const allFilterData = this.searchFacade.getAllFilterData() ?? [];
        const selectedFilters = this.searchFacade.getSelectedFilters();
        const applicableFilterData = this.searchFacade.applicableFilterData();

        return allFilterData.map(data => {
            const filters = data.filters.map(filter => {
                const isActive = (selectedFilters.get(data.id) ?? []).includes(filter);
                const isDisabled = applicableFilterData.every(applicableData => !applicableData.filters.includes(filter));
                return new Filter(filter, isActive, isDisabled);
            });
            const isBlockDisabled = applicableFilterData.every(applicableData => applicableData.id !== data.id);
            return new FilterBlock(data.id, filters, data.label, isBlockDisabled);
        });
    }

    public getTotalCount(): number {
        return this.totalCount;
    }

    public async updateTotalCount(): Promise<void> {
        await this.updateResultCount();
    }

    public resetToAppliedState(): void {
        this.searchFacade.revertFilters();
    }

    public isResettable(): boolean {
        return this.isChanged() || this.searchFacade.getSelectedFilters().size > 0;
    }

    public isChanged(): boolean {
        return this.searchFacade.hasUncommittedFilters();
    }

    private updateCount(totalCount: number): void {
        this.totalCount = Math.max(totalCount, 0);
    }

    private async updateResultCount(): Promise<void> {
        const filters = this.searchFacade.getSelectedFilters();
        this.countUpdater.rejectExpected();
        const deferred = new Deferred<number>();
        this.countUpdater = deferred;

        const totalCountPromise = deferred.promise
            .then(totalCount => this.totalCount = totalCount)
            .catch(EOP_ERRORS);

        this.searchFacade.requestActiveResultsCount(filters)
            .then(response => deferred.resolve(response));
        const spinnerForResultCount = this.promises.decoratorFor("result-count")(totalCountPromise);
        await schedule(spinnerForResultCount).as("update-result-count");
    }

    private persistFilters(filters: Filters): void {
        this.queryParameters.removeParametersStartingWith(SEARCH_FILTER_QUERY_PARAMETER_PREFIX);
        filters.forEach((values, category) => {
            this.queryParameters.appendAll(category, values);
        });
    }
}