import {resolve} from "../../container";
import {LocatedDates} from "../../common/utils/dates/locatedDates";
import {schedule} from "../../common/utils/promises";
import {DefaultFeedSource} from "./feedSource";
import {FeedEntry, FeedEntryData} from "./feedEntry";
import {html, LitElement, PropertyValues, TemplateResult} from "lit";
import {customElement, property, query, state} from "lit/decorators.js";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import Styles from "./teaserSequence.lit.scss";
import {UnLitElement} from "../../common/elements";
import type {EopOverlaySpinner, Spinner} from "../../page/elements/spinner";

@customElement("eop-teaser-sequence")
export class EopTeaserSequence extends LitElement {

    public static readonly styles = Styles;

    @query("eop-image-spinner")
    protected fetchSpinner: Spinner;
    @query("eop-overlay-spinner")
    protected fetchMoreSpinner: EopOverlaySpinner;
    @property({attribute: "number", type: Number})
    private limit: number = 3;
    @property({attribute: "sources"})
    private sources: string;
    @property({attribute: "keywords"})
    private keywords: string;
    @property({attribute: "excluded-keywords"})
    private excludedKeywords: string;
    @property({attribute: "character-limit", type: Number})
    private characterLimit: number = 180;
    @property({attribute: "language"})
    private language: string;
    @property({attribute: "information-density"})
    private informationDensity: string;
    @state()
    private feedEntries: FeedEntry[];
    private fetchMoreAmount: number;

    public constructor(
        private feedSource: DefaultFeedSource = new DefaultFeedSource()
    ) {
        super();
        this.feedEntries = [];
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.feedSource.configure({
            sources: this.sources?.parseAsJSON() ?? {},
            keywords: this.keywords?.parseCommaSeparated() ?? [],
            excludedKeywords: this.excludedKeywords?.parseCommaSeparated() ?? [],
            characterLimit: this.characterLimit,
            language: this.language ?? ""
        });
        this.fetchMoreAmount = this.getAttribute("fetch-more-amount")?.toInt() ?? 0;
    }

    public render(): TemplateResult {
        return html`
            <eop-overlay-spinner></eop-overlay-spinner>
            <eop-image-spinner></eop-image-spinner>
            <div class="social-teasers">
                ${this.renderTiles()}
            </div>
            ${this.renderFetchMoreButton()}
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        schedule(this.fetchSpinner.spinWhile(this.fetchTiles(this.limit, 0)
            .then(tiles => this.feedEntries = tiles)))
            .as("fetch");
    }

    private renderTiles(): TemplateResult[] | null {
        if (this.feedEntries.isEmpty()) {
            return null;
        }
        return this.feedEntries.map(entry => html`
            <eop-teaser-sequence-tile .entry=${entry} class="${this.informationDensity} social-teaser-container"></eop-teaser-sequence-tile>`
        );
    }

    private renderFetchMoreButton(): TemplateResult | null {
        return this.showFetchMore() ? html`
            <div class="fetch-more-button">
                <button
                        class="primary"
                        type="button"
                        data-eventelement="button"
                        @click=${this.fetchMore}
                >
                    <eop-msg key="MSG_TEASER_FETCH_MORE"></eop-msg>
                </button>
            </div>` : null;
    }

    private showFetchMore(): boolean {
        return this.fetchMoreAmount > 0
            && this.feedSource.total() > this.feedEntries.length;
    }

    private fetchMore(): void {
        const offset = this.feedEntries.length;
        schedule(this.fetchMoreSpinner.spinWhile(this.fetchTiles(this.fetchMoreAmount, offset)
            .then(tiles => this.feedEntries = this.feedEntries.slice(0, offset).concat(tiles))))
            .as("fetch-more");
    }

    private async fetchTiles(size: number, offset: number): Promise<FeedEntry[]> {
        const entries = await this.feedSource.fetch(size, offset);
        return entries.map(entry => {
            return FeedEntry.fromResponse(entry)
                .inChannelContext(this.feedSource.channels());
        });
    }
}

@customElement("eop-teaser-sequence-tile")
export class EopTeaserSequenceTile extends UnLitElement {

    @property()
    public entry: FeedEntry;

    private data: FeedEntryData;

    public constructor(
        private dates: LocatedDates = resolve(LocatedDates)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.data = this.entry.data;
    }

    public render(): TemplateResult {
        const trackingLabel = this.data.headline ?? "no-headline";
        return html`
            <div class="social-teaser">
                <a href=${this.data.url}
                   class="teaser-image"
                   data-eventelement="teaser-sequence-tile-image"
                   data-tracking-label=${trackingLabel}
                >
                    ${this.imageElement()}
                </a>
                <a
                        href=${this.data.url}
                        class="teaser-text reverse-background"
                        data-eventelement="teaser-sequence-tile-text"
                        data-tracking-label=${trackingLabel}
                >
                    <div class="tile-top">
                        <div class="metadata">${this.getMetaData()}</div>
                    </div>
                    <div class="teaser-headline">${unsafeHTML(this.data.headline) ?? ""}</div>
                    <div class="teaser-description">${this.data.subHeadline ?? ""}</div>
                    <div class="more-link">
                        <span class="link common-link">
                            <eop-msg key="MSG_TEASER_SEQUENCE_READ_MORE"></eop-msg>
                        </span>
                    </div>
                </a>
            </div>`;
    }

    private imageElement(): TemplateResult {
        return html`
            <eop-responsive-image image-src=${this.entry.getImageUrlFor7x4Purpose()} image-alt=${this.data.imageAltText}></eop-responsive-image>`;
    }

    private getMetaData(): string {
        const date = this.dates.toLocalDateString(new Date(this.data.publishedDate));
        if (this.data.principalKeyword) {
            return `${date} | ${this.data.principalKeyword}`;
        } else {
            return `${date}`;
        }
    }
}