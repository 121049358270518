import {UrlBuilder} from "../../../common/utils/url";
import {Nav} from "../../../common/nav";
import {resolve} from "../../../container";
import {SEARCH_QUERY_PARAMETER_NAME} from "./searchGroup";
import {customElement, property, query} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./newsSearchSlot.lit.scss";

@customElement("eop-news-search-slot")
export class EopNewsSearchSlot extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "placeholder"})
    private placeholder: string;
    @property({attribute: "target-url"})
    private targetUrl: string;
    @query("input")
    private input: HTMLInputElement;

    public constructor(private nav: Nav = resolve(Nav)) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <form class="content-search-slot"
                  data-tracking-label="news-search-slot"
                  @submit=${this.handleSubmit}
            >
                <eop-resettable-input class="content-search-input" placeholder=${this.placeholder}></eop-resettable-input>
                <button type="submit" class="content-search-button" data-tracking-label="news-search-submit"></button>
            </form>
        `;
    }

    private handleSubmit(event: SubmitEvent): void {
        event.preventDefault();

        if (this.targetUrl === "") {
            return;
        }
        const url = new UrlBuilder(this.targetUrl)
            .withQueryParameter(SEARCH_QUERY_PARAMETER_NAME, this.input.value)
            .build();
        this.nav.redirect(url);
    }
}