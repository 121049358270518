import {customElement, state} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./siteSearchTiles.lit.scss";
import {SearchFacade} from "../searchFacade";
import {resolve} from "../../../../container";
import type {FeedEntry} from "../../feedEntry";
import type {SearchTileData} from "../searchGroup";


@customElement("eop-site-search-tiles")
export class EopSiteSearchTiles extends LitElement {

    public static readonly styles = Styles;

    @state()
    private tiles: SearchTileData[];

    public constructor(private searchFacade: SearchFacade = resolve(SearchFacade)) {
        super();
        this.tiles = [];
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.searchFacade.onChangeGroup(group => {
            this.tiles = this.mapEntries(group.entries, group.name);
        });
        this.searchFacade.onNewEntries(group => {
            this.tiles = this.mapEntries(group.entries, group.name);
        });
    }

    public render(): TemplateResult {
        return html`
            <div class="tiles">${(this.renderTiles())}</div>`;
    }

    private renderTiles(): TemplateResult[] {
        return this.tiles.map(tileData => html`
            <eop-site-search-tile .entry=${tileData.entry} .groupName=${tileData.groupName} class="tile"></eop-site-search-tile>`);
    }

    private mapEntries(entries: FeedEntry[], groupName: string): SearchTileData[] {
        return entries.map(entry => ({entry: entry, groupName: groupName}));
    }
}