import {FeedService, FilterDataResponse, PageResponse} from "../../feedService";
import {FiltersRequest, SiteSearchCountRequest, SiteSearchRequest} from "../../requests";
import {resolve} from "../../../../container";
import type {Filters} from "../searchFacade";
import type {SearchBackend, SearchRequest} from "../searchBackend";

export class SiteSearchBackend implements SearchBackend {

    public constructor(private feedService: FeedService = resolve(FeedService)) {
    }

    public async fetchEntries(
        req: SearchRequest,
        sources: string[],
        searchPhrase: string,
        limit: number,
        offset: number,
        filters: Filters = new Map()
    ): Promise<PageResponse> {
        const request = new SiteSearchRequest(
            req.lang, limit, offset, searchPhrase, sources, req.charactersLimit, filters, req.archived);
        return await this.feedService.search(request);
    }

    public async fetchResultsCount(req: SearchRequest, sources: string[], searchPhrase: string, filters: Filters): Promise<number> {
        const request = new SiteSearchCountRequest(req.lang, searchPhrase, sources, filters, req.archived);
        const response = await this.feedService.searchCount(request);

        return response.totalCount;
    }

    public async fetchFilterData(req: SearchRequest, sources: string[]): Promise<FilterDataResponse[]> {
        const request = new FiltersRequest(req.lang, sources, req.archived);
        return (await this.feedService.filters(request)).filterData;
    }

}