import type {PropertyMap} from "../../../common/utils/objects";
import type {FeedChannel} from "../feedCommons";
import type {FeedSource} from "../feedSource";
import {ContentEntryResponse, FeedService} from "../feedService";
import {resolve} from "../../../container";
import {FeedRelatedRequest} from "../requests";
import {AbstractFeedPreviewGrid} from "./feedPreviewCommons";
import {customElement, property} from "lit/decorators.js";

export type RelatedFeedSourceConfig = {
    language: string;
    sources: PropertyMap<FeedChannel>;
    maxDescriptionLength: number;
    relatedContentUrl: string;
}

export class RelatedFeedSource implements FeedSource {
    private config: RelatedFeedSourceConfig;
    private totalCount: number;

    public constructor(private feedService: FeedService = resolve(FeedService)) {
        this.totalCount = 0;
    }

    public configure(config: RelatedFeedSourceConfig): this {
        this.config = config;
        return this;
    }

    public total(): number {
        return this.totalCount;
    }

    public async fetch(size: number, offset?: number): Promise<ContentEntryResponse[]> {
        const response = await this.feedService.related(new FeedRelatedRequest(
            this.config.language,
            size,
            offset ?? 0,
            Object.keys(this.config.sources),
            this.config.maxDescriptionLength,
            this.config.relatedContentUrl
        ));
        this.totalCount = response.totalCount;

        return response.entries;
    }

    public channels(): PropertyMap<FeedChannel> {
        return this.config.sources;
    }
}

@customElement("eop-feed-related-preview")
export class EopFeedRelatedPreview extends AbstractFeedPreviewGrid<RelatedFeedSource> {

    @property({attribute: "language"})
    public language: string = "";
    @property({attribute: "sources"})
    public sources: string;
    @property({attribute: "related-content-url"})
    public relatedContentUrl: string = "";
    @property({attribute: "show-more", type: Boolean})
    public enableMore: boolean = false;

    public constructor(feedSource: RelatedFeedSource = new RelatedFeedSource()) {
        super(feedSource);
    }

    protected configure(): void {
        this.feedSource.configure({
            sources: this.sources?.parseAsJSON() ?? {},
            relatedContentUrl: this.relatedContentUrl,
            maxDescriptionLength: this.characterLimit,
            language: this.language
        });
    }
}