import {customElement, property} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./filterBlock.lit.scss";
import {classMap} from "lit/directives/class-map.js";

export class Filter {
    public constructor(
        public value: string,
        public active: boolean = false,
        public disabled: boolean = false
    ) {
    }
}

@customElement("eop-search-filter-block")
export class EopSearchFilterBlock extends LitElement {

    public static readonly styles = Styles;

    @property({type: Boolean, reflect: true})
    public disabled: boolean = false;
    @property({type: Array})
    private filters: Filter[];

    public constructor(
        public category: string,
        private label: string
    ) {
        super();
        this.filters = [];
    }

    public render(): TemplateResult {
        const classes = {"two-columns": this.twoColumns()};
        return html`
            <div class="filter-block ${classMap(classes)}">
                <div class="filter-label">${this.label}</div>
                ${(this.renderSearchFilters())}
            </div>
        `;
    }

    private renderSearchFilters(): TemplateResult[] {
        return this.filters.map(filter => html`
            <eop-search-filter-value
                    .category=${this.category}
                    .value=${filter.value}
                    ?active=${filter.active}
                    ?disabled=${filter.disabled}
            ></eop-search-filter-value>`
        );
    }

    private twoColumns(): boolean {
        return Math.max(...this.filters.map(filter => filter.value.length)) > 4;
    }
}