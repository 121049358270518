import {customElement} from "lit/decorators.js";
import Styles from "./newsSearchResultsTile.lit.scss";
import {SearchTile} from "../searchTile";
import {html, TemplateResult} from "lit";
import {ContentSearchImages} from "../contentSearchImages";
import {resolve} from "../../../../container";
import {keyed} from "lit/directives/keyed.js";
import type {FeedEntryTopic} from "../../feedEntry";

@customElement("eop-news-search-results-tile")
export class EopNewsSearchResultsTile extends SearchTile {

    public static readonly styles = Styles;

    public constructor(private images: ContentSearchImages = resolve(ContentSearchImages)) {
        super();
    }

    public render(): TemplateResult {
        return html`${keyed(this.entry.data.url + this.groupName, this.renderTile())}`;
    }

    protected renderImageElement(): TemplateResult | null {
        return this.images.imageWithStaticAspectRatio(this.entry.getImageUrlFor4x3Purpose(), this.entry.data.imageAltText);
    }

    protected renderTopicLinks(): TemplateResult | null {
        const feedEntryTopics = this.entry.data.topics;
        if (!feedEntryTopics || feedEntryTopics.isEmpty()) {
            return null;
        }

        return html`
            <div class="tags" data-eventelement="feedtag">${(this.renderTopicTags(feedEntryTopics))}</div>`;
    }

    private renderTopicTags(feedEntryTopics: FeedEntryTopic[]): TemplateResult[] {
        return feedEntryTopics.map(topic => html`
            <eop-news-tag
                    @mouseover=${this.removeHoverClass}
                    @mouseout=${this.addHoverClass}
                    .text=${topic.name}
                    data-tracking-label=${topic.name}>
            </eop-news-tag>
        `);
    }

    protected showDescription(): boolean {
        return true;
    }

    protected headlineText(): string {
        return this.entry.data.headline ?? "---";
    }

    protected subHeadlineText(): string {
        return this.entry.data.subHeadline ?? "";
    }
}