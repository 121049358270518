import type {FeedSource} from "../feedSource";
import {ContentEntryResponse, FeedService} from "../feedService";
import {resolve} from "../../../container";
import {FeedEntriesByUrlsRequest} from "../requests";
import type {PropertyMap} from "../../../common/utils/objects";
import type {FeedChannel} from "../feedCommons";
import {customElement, property} from "lit/decorators.js";
import {AbstractFeedPreviewGrid, AbstractFeedPreviewSlider} from "./feedPreviewCommons";

export type UrlSpecificFeedSourceConfig = {
    maxDescriptionLength: number;
    urls: string[];
}

export class UrlSpecificFeedSource implements FeedSource {
    private config: UrlSpecificFeedSourceConfig;
    private totalCount: number;

    public constructor(private feedService: FeedService = resolve(FeedService)) {
        this.totalCount = 0;
    }

    public configure(config: UrlSpecificFeedSourceConfig): this {
        this.config = config;
        return this;
    }

    public total(): number {
        return this.totalCount;
    }

    public async fetch(): Promise<ContentEntryResponse[]> {
        const response = await this.feedService.entriesByUrls(new FeedEntriesByUrlsRequest(
            this.config.urls,
            this.config.maxDescriptionLength
        ));
        this.totalCount = response.totalCount;

        return response.entries;
    }

    public channels(): PropertyMap<FeedChannel> {
        return {};
    }
}

@customElement("eop-page-preview")
export class EopPagePreview extends AbstractFeedPreviewGrid<UrlSpecificFeedSource> {

    @property({attribute: "urls"})
    public urls: string;

    public constructor(feedSource: UrlSpecificFeedSource = new UrlSpecificFeedSource()) {
        super(feedSource);
    }

    protected configure(): void {
        this.feedSource.configure({
            maxDescriptionLength: this.characterLimit,
            urls: this.urls?.parseCommaSeparated() ?? []
        });
    }

}

@customElement("eop-page-preview-slider")
export class EopPagePreviewSlider extends AbstractFeedPreviewSlider<UrlSpecificFeedSource> {

    @property({attribute: "urls"})
    public urls: string;

    public constructor(feedSource: UrlSpecificFeedSource = new UrlSpecificFeedSource()) {
        super(feedSource);
    }

    protected configure(): void {
        this.feedSource.configure({
            maxDescriptionLength: this.characterLimit,
            urls: this.urls?.parseCommaSeparated() ?? []
        });
    }
}
