import type {PropertyMap} from "../../common/utils/objects";
import {ContentEntryResponse, FeedService} from "./feedService";
import {resolve} from "../../container";
import {FeedEntriesRequest} from "./requests";
import type {FeedChannel} from "./feedCommons";

export interface FeedSource {

    fetch: (size: number, offset?: number) => Promise<ContentEntryResponse[]>;

    total: () => number;

    channels: () => PropertyMap<FeedChannel>;
}

export type DefaultFeedSourceConfig = {
    language: string;
    keywords: string[];
    sources: PropertyMap<FeedChannel>;
    characterLimit: number;
    excludedKeywords: string[];
}

export class DefaultFeedSource implements FeedSource {
    private config: DefaultFeedSourceConfig;
    private totalCount: number;

    public constructor(private feedService: FeedService = resolve(FeedService)) {
        this.totalCount = 0;
    }

    public configure(config: DefaultFeedSourceConfig): this {
        this.config = config;
        return this;
    }

    public total(): number {
        return this.totalCount;
    }

    public async fetch(size: number, offset?: number): Promise<ContentEntryResponse[]> {
        const response = await this.feedService.entries(new FeedEntriesRequest(
            this.config.language,
            size,
            offset ?? 0,
            this.config.keywords,
            Object.keys(this.config.sources),
            this.config.characterLimit,
            this.config.excludedKeywords
        ));
        this.totalCount = response.totalCount;

        return response.entries;
    }

    public channels(): PropertyMap<FeedChannel> {
        return this.config.sources;
    }
}