import {html, LitElement, TemplateResult} from "lit";
import {customElement} from "lit/decorators.js";
import Styles from "./lightboxDialog.lit.scss";

@customElement("eop-lightbox-dialog")
export class LightboxDialog extends LitElement {

    public static readonly styles = Styles;

    public constructor(
        private headline: string,
        private subheadline: string,
        private url: string,
        private lightboxImageUrl: string,
        private imgAltText: string,
        private buttonLabel: string
    ) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <div class=lightbox-dialog>
                <h3>${this.headline}</h3>
                ${this.renderSubheadline()}
                <a href=${this.url} download class="lightbox-download secondary" data-eventelement="lightbox-download">${this.buttonLabel}</a>
                <div class=download-lightbox-image>
                    <eop-responsive-image
                            .imageSrc=${this.lightboxImageUrl}
                            .imageAlt=${this.imgAltText}
                            intrinsic-aspect-ratio
                    ></eop-responsive-image>
                </div>
            </div>
        `;
    }

    private renderSubheadline(): TemplateResult | null {
        if (!this.subheadline) {
            return null;
        }

        return html`
            <div class="download-lightbox-description">${this.subheadline}</div>
        `;
    }
}

export namespace LightboxDialogFactory {
    export function create(
        headline: string,
        subheadline: string,
        url: string,
        lightboxImageUrl: string,
        imgAltText: string,
        buttonLabel: string
    ): LightboxDialog {
        return new LightboxDialog(headline, subheadline, url, lightboxImageUrl, imgAltText, buttonLabel);
    }
}