import {customElement, property, query, state} from "lit/decorators.js";
import {EventBus} from "../../../common/eventBus";
import {resolve} from "../../../container";
import {Timeout} from "../../../common/timeout";
import {QueryParameters} from "../../../common/queryParameters";
import {ScrollService} from "../../../common/scroll";
import {NEWS_TAG_EVENT} from "./newsTag";
import {NEWS_SEARCH_PHRASE_EVENT, SEARCH_QUERY_PARAMETER_NAME} from "./searchGroup";
import {SearchFacade} from "./searchFacade";
import {ManagingResources} from "../../../common/lifetime";
import type {PropertyValues, TemplateResult} from "lit";
import {html, LitElement} from "lit";
import Styles from "./contentSearchSlot.lit.scss";

const INPUT_DEBOUNCE = 1200;

@customElement("eop-content-search-slot")
export class EopContentSearchSlot extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "placeholder"})
    private placeholder: string;
    @query("input", true)
    private input: HTMLInputElement;
    @query("button[type='reset']", true)
    private reset: HTMLButtonElement;

    @state()
    private value: string;

    private inputPromise: Promise<void> | undefined;

    public constructor(
        private eventBus: EventBus = resolve(EventBus),
        private timeout: Timeout = resolve(Timeout),
        private searchFacade: SearchFacade = resolve(SearchFacade),
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <form class="content-search-slot" data-tracking-label="search-slot" @submit=${this.submit}>
                <div class="content-search-input">
                    <input id="news-search"
                           type="text"
                           class="form-control"
                           placeholder=${this.placeholder}
                           @input=${this.inputHandler}
                    >
                    <button type="reset" data-tracking-label="search-slot-reset" @click=${this.clickHandler}></button>
                </div>
                <button type="submit" class="content-search-button" data-tracking-label="search-slot-submit"></button>
            </form>`;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.eventBus.on(NEWS_SEARCH_PHRASE_EVENT, event => {
            this.input.value = event.data.searchPhrase;
            this.value = this.input.value;
            this.queryParameters.set(SEARCH_QUERY_PARAMETER_NAME, this.value);
        }, this);

        this.eventBus.on(NEWS_TAG_EVENT, event => {
            this.input.value = event.data.text;
            this.triggerSearch();
            this.scrollService.scrollToElement(this);
        }, this);
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.searchFacade.registerFilterInitialization(async (sf) => {
            this.queryParameters.onString(SEARCH_QUERY_PARAMETER_NAME, searchPhrase => {
                this.input.value = searchPhrase;
                this.updateResetButtonVisibility();
                sf.initSearchPhrase(searchPhrase);
            });
            return sf;
        });
    }

    private clickHandler(): void {
        this.input.value = "";
        this.triggerSearch();
        this.input.focus();
    }

    private submit(event: SubmitEvent): void {
        event.preventDefault();
        this.triggerSearch();
    }

    private inputHandler(): void {
        if (this.inputPromise) {
            this.timeout.cancel(this.inputPromise);
        }
        this.updateResetButtonVisibility();
        this.inputPromise = this.timeout.delay(() => this.triggerSearch(), INPUT_DEBOUNCE);
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        this.updateResetButtonVisibility();
    }

    private updateResetButtonVisibility(): void {
        if (this.input.value) {
            this.reset.show();
        } else {
            this.reset.hide();
        }
    }

    private triggerSearch(): void {
        if (this.value === this.input.value) {
            return;
        }
        this.value = this.input.value;
        this.eventBus.dispatchEvent(NEWS_SEARCH_PHRASE_EVENT, {searchPhrase: this.value});
    }
}