import {VisitRecord} from "./visitRecord";
import {FeedService, FilterDataResponse, PageResponse} from "../feedService";
import {FiltersRequest, NewsCountRequest, NewsRequest} from "../requests";
import {autoRegister, resolve} from "../../../container";
import type {Filters} from "./searchFacade";
import type {SearchBackend, SearchRequest} from "./searchBackend";

@autoRegister()
export class NewsSearchBackend implements SearchBackend {
    public constructor(private visitRecord: VisitRecord = resolve(VisitRecord), private feedService: FeedService = resolve(FeedService)) {
    }

    public async fetchEntries(
        req: SearchRequest,
        sources: string[],
        searchPhrase: string,
        limit: number,
        offset: number,
        filters: Filters = new Map()
    ): Promise<PageResponse> {
        const request = new NewsRequest(
            req.lang, limit, offset, searchPhrase, sources, req.charactersLimit, this.visitRecord.getLastSessionDate(), filters, req.archived);
        return await this.feedService.news(request);
    }

    public async fetchResultsCount(req: SearchRequest, sources: string[], searchPhrase: string, filters: Filters): Promise<number> {
        const request = new NewsCountRequest(req.lang, searchPhrase, sources, filters, req.archived);
        const response = await this.feedService.newsCount(request);
        return response.totalCount;
    }

    public async fetchFilterData(req: SearchRequest, sources: string[]): Promise<FilterDataResponse[]> {
        const request = new FiltersRequest(req.lang, sources, req.archived);
        return (await this.feedService.filters(request)).filterData;
    }

}
